import { Switch, Route } from 'react-router-dom';
import { MenuPage, DetailsPage } from './pages';
import LandingPage from './pages/LandingPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfUsePage from './pages/TermsOfUsePage';
import WeBelievePage from './pages/WeBelievePage';

function Routes() {
  return (
    <Switch>
      <Route path='/:id/menu' component={MenuPage} exact />
      <Route path='/:id/details' component={DetailsPage} exact />
      <Route path='/' component={LandingPage} exact />
      <Route path='/believe' component={WeBelievePage} exact />
      <Route path='/privacy-policy' component={PrivacyPolicyPage} exact />
      <Route path='/terms-of-use' component={TermsOfUsePage} exact />
    </Switch>
  );
}

export default Routes;
