import React from 'react';
import FeaturesItem from '../FeaturesItem';
import { FEATURES_ITEMS } from '../../../utils/landingContent';
import FeaturesBG from './../../../assets/images/features-bg.png';

import styles from './styles.module.scss';

const Features = () => {
  return (
    <div className={styles.features} id="features">
      <div className={styles.background}>
        <img src={FeaturesBG} alt='' />
      </div>
      <div className={styles.content}>
        <h3 className={styles.title}>What makes Nova Menu perfect for you</h3>
        <span className={styles.subtitle}>
          First set of features for you, but not the last
        </span>
        <div className={styles.featureItems}>
          {FEATURES_ITEMS.map((feature) => (
            <FeaturesItem
              {...feature}
              key={feature.title}
              className={styles.feature}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
