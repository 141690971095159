import { makeAutoObservable } from 'mobx';
import { apiClient } from '../utils/apiClient';
import { EMAIL_URL } from '../utils/constants';

class ModalStore {
  isOpen = false;

  constructor() {
    makeAutoObservable(this);
  }

  openModal = () => (this.isOpen = true);

  closeModal = () => (this.isOpen = false);

  submitEmail = (email: string) => {
    try {
      apiClient.post(EMAIL_URL, {message: email})
    } catch (e) {
      
    }
  }
}

export default new ModalStore();
