import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import MenuStore from '../../stores/MenuStore';
import MenuFooter from '../../components/MenuCommon/MenuFooter';
import MenuHeader from '../../components/MenuCommon/MenuHeader';
import styles from './styles.module.scss';
import Loader from '../../components/Common/Loader';

const MenuPage = observer(() => {
  const [numPages, setNumPages] = useState(null);

  const { id } = useParams<{ id: string }>();

  const { loadMenuData, menuData, isLoading } = MenuStore;

  useEffect(() => {
    if (!menuData) {
      loadMenuData(id);
    }
  }, [id, loadMenuData, menuData]);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const clientWidth = document.getElementById('root')?.clientWidth || 0

  console.log(clientWidth)

  const pageWidth = clientWidth > 768 ? 768 : clientWidth

  console.log(pageWidth)
  if (isLoading || !menuData) return <Loader />;

  return (
    <div className={styles.menuPage}>
      <div className={styles.container}>
        <MenuHeader />
        <div className={styles.menuPdf}>
          <Document
            file={menuData?.pdf}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<Loader />}
            renderMode='svg'
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                width={pageWidth}
              />
            ))}
          </Document>
        </div>
        <MenuFooter id={id} />
      </div>
    </div>
  );
});

export default MenuPage;
