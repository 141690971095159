import React from 'react';
import { iLinkButton } from './types';

import styles from './styles.module.scss';
import clsx from 'clsx';

const LinkButton = ({
  text,
  svg,
  svgPosition,
  onClick = () => {},
  secondary,
  className = '',
  SvgElement,
}: iLinkButton) => {
  const getSvg = (
    svg: any,
    svgPosition: any,
    SvgElement: any,
    className: string
  ) => {
    if (!svg && !svgPosition) return null;

    if (SvgElement)
      return (
        <SvgElement
          className={clsx({
            [styles.hoverSvg]: true,
            [className]: true,
          })}
        />
      );

    return <img src={svg} alt={text} className={className} />;
  };

  return (
    <button
      className={clsx({
        [styles.linkButton]: true,
        [className]: className,
      })}
      onClick={onClick}
    >
      {svgPosition === 'start' &&
        getSvg(svg, svgPosition, SvgElement, styles.svgStart)}
      <span>{text}</span>
      {(!svgPosition || svgPosition === 'end') &&
        getSvg(svg, svgPosition, SvgElement, styles.svgEnd)}
    </button>
  );
};

export default LinkButton;
