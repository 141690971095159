import React from 'react';
import clsx from 'clsx';
import { iPricingItemComponent } from './types';
import { ReactComponent as PricingFeature } from './../../../../assets/svg/pricing-feature.svg';

import styles from './styles.module.scss';
import DefaultButton from '../../../Common/DefaultButton';
import { observer } from 'mobx-react-lite';
import ModalStore from '../../../../stores/ModalStore';

const PricingItem = observer(({ item }: iPricingItemComponent) => {
  const { openModal } = ModalStore;

  const period =
    window.innerWidth < 1000 && window.innerWidth > 640
      ? item.period
      : ` / ${item.period}`;

  return (
    <div
      className={clsx({
        [styles.pricingItem]: true,
        [styles.pricingItemHighlighted]: item.highlighted,
      })}
    >
      <div className={styles.header}>
        <div className={styles.name}>{item.name}</div>
        <div className={styles.plan}>
          <div className={styles.cost}>{item.cost}</div>
          <div className={styles.period}>{period}</div>
        </div>
      </div>
      <div className={styles.features}>
        {item.features.map((feature, index) => (
          <div className={styles.featuresItem}>
            <PricingFeature className={styles.check} key={index} />
            {feature}
          </div>
        ))}
      </div>
      <DefaultButton
        text='Choose Plan'
        className={styles.button}
        secondary={item.highlighted}
        onClick={openModal}
      />
    </div>
  );
});

export default PricingItem;
