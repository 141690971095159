import React from 'react';
import PlacesItem from './PlacesItem';
import { PLACE_ITEMS } from '../../../utils/landingContent';
import PerfectlyFitBG from './../../../assets/images/perfectlyFit-bg.png';

import styles from './styles.module.scss';

const PerfectlyFit = () => {
  return (
    <div className={styles.perfectlyFit}>
      <div className={styles.background}>
        <img src={PerfectlyFitBG} alt='' />
      </div>
      <div className={styles.content}>
        <h3 className={styles.title}>Perfectly fit for</h3>
        <span className={styles.subtitle}>
          Show your food and drinks digital and interactive
        </span>
        <div className={styles.places}>
          {PLACE_ITEMS.map((place, index) => (
            <PlacesItem {...place} className={styles.place} key={index}/>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PerfectlyFit;
