import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import ModalStore from '../../../stores/ModalStore';
import clsx from 'clsx';
import { HEADER_NAV } from '../../../utils/constants';
import DefaultButton from '../DefaultButton';
import Logo from './../../../assets/images/logo.png';
import Burger from './../../../assets/svg/burger.svg';

import styles from './styles.module.scss';
import MobileMenu from './MobileMenu';
import LinkButton from '../LinkButton';
import { observer } from 'mobx-react-lite';

const Header = observer(() => {
  const { openModal } = ModalStore;

  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleOnScroll = () => {
      setScrolled(window.pageYOffset > 10);
    };

    handleOnScroll();
    window.addEventListener('scroll', handleOnScroll);

    return () => window.removeEventListener('scroll', handleOnScroll);
  }, []);

  const isOpenHandler = () => {
    setIsOpen((prev) => !prev);
    document.body.classList.toggle('hidden');
  };

  return (
    <>
      <div
        className={clsx({
          [styles.header]: true,
          [styles.scrolled]: scrolled,
        })}
      >
        <div className={styles.container}>
          <div className={styles.content}>
            <Link to='/'>
              <img src={Logo} alt='' className={styles.logo} />
            </Link>
            <nav className={styles.nav}>
              {HEADER_NAV.map((item) =>
                item.isHashLink ? (
                  <HashLink to={item.path} className={styles.navItem} smooth key={item.path}>
                    {item.title}
                  </HashLink>
                ) : (
                  <Link to={item.path} className={styles.navItem} key={item.path}>
                    {item.title}
                  </Link>
                )
              )}
            </nav>
          </div>
          <div className={styles.buttons}>
            <span onClick={openModal}>
              <LinkButton text='Sign Up' className={styles.signUp} />
            </span>
            <span className={styles.login} onClick={openModal}>
              <DefaultButton text='Login' />
            </span>
          </div>
          <div className={styles.burger} onClick={isOpenHandler}>
            <img src={Burger} alt='' />
          </div>
        </div>
      </div>
      <MobileMenu isOpen={isOpen} isOpenHandler={isOpenHandler} />
    </>
  );
});

export default Header;
