import YouTube from './../assets/svg/youtube.svg';
import Snapchat from './../assets/svg/snapchat.svg';
import Pinterest from './../assets/svg/pinterest.svg';
import Instagram from './../assets/svg/instagram.svg';
import Facebook from './../assets/svg/facebook.svg';
import Twitter from './../assets/svg/twitter.svg';

import Tripadvisor from './../assets/svg/reviews/tripadvisor.svg';
import Google from './../assets/svg/reviews/GB.svg';
import Yelp from './../assets/svg/reviews/yelp.svg';
import Foody from './../assets/svg/reviews/foody.svg';
import Dianping from './../assets/svg/reviews/dianping.svg';

import { iSocialLinks, iReviewLinks } from './types';
import { iWorkdaysItem } from '../components/DetailsCommon/types';

export const BASE_URL =
  'https://g790rr2rj6.execute-api.us-east-1.amazonaws.com/prod';

export const EMAIL_URL = 'https://7f6b5q7afg.execute-api.us-east-1.amazonaws.com/Prod/send'

export const RATE_STAGES = {
  START: 'START',
  SAD_FORM: 'SAD_FORM',
  SAD_FINAL: 'SAD_FINAL',
  LOVE_FINAL: 'LOVE_FINAL',
};

export const SOCIALS: iSocialLinks = {
  instagram: {
    id: 'instagram',
    icon: Instagram,
  },
  twitter: {
    id: 'twitter',
    icon: Twitter,
  },
  facebook: {
    id: 'facebook',
    icon: Facebook,
  },
  pinterest: {
    id: 'pinterest',
    icon: Pinterest,
  },
  snapchat: {
    id: 'snapchat',
    icon: Snapchat,
  },
  youtube: {
    id: 'youtube',
    icon: YouTube,
  },
};

export const REVIEWS: iReviewLinks = {
  tripadvisor: {
    id: 'tripadvisor',
    title: 'Tripadvisor',
    icon: Tripadvisor,
  },
  google: {
    id: 'google',
    title: 'Google Business',
    icon: Google,
  },
  yelp: {
    id: 'yelp',
    title: 'Yelp',
    icon: Yelp,
  },
  foody: {
    id: 'foody',
    title: 'Foody.vn',
    icon: Foody,
  },
  dianping: {
    id: 'dianping',
    title: 'Dianping',
    icon: Dianping,
  },
};

export const WORKDAYS: iWorkdaysItem[] = [
  {
    title: 'Sunday',
    key: 'sun',
  },
  {
    title: 'Monday',
    key: 'mon',
  },
  {
    title: 'Tuesday',
    key: 'tue',
  },
  {
    title: 'Wednesday',
    key: 'wed',
  },
  {
    title: 'Thursday',
    key: 'thu',
  },
  {
    title: 'Friday',
    key: 'fri',
  },
  {
    title: 'Saturday',
    key: 'sat',
  },
];

export const HEADER_NAV = [
  {
    title: 'Features',
    path: '/#features',
    isHashLink: true,
  },
  {
    title: 'Pricing',
    path: '/#pricing',
    isHashLink: true,
  },
  {
    title: 'We believe',
    path: '/believe',
    isHashLink: false,
  },
  {
    title: 'Contacts',
    path: '#contacts',
    isHashLink: true,
  },
];
