import React from 'react';
import { SOCIALS } from '../../../utils/constants';

import styles from './styles.module.scss';
import { iSocialItem } from './types';

const SocialItem = ({social}: iSocialItem) => {

  const socialItem = SOCIALS[social.type]

  if (!socialItem) return null

  return (
    <a className={styles.socialItem} href={social.link}>
      <img src={socialItem.icon} alt={social.type} />
    </a>
  )
}

export default SocialItem;
