import React from 'react';
import LoaderIcon from './../../../assets/svg/loader.svg';

import styles from './styles.module.scss';

const Loader = () => {
  return (
    <div className={styles.loader}>
      <object type='image/svg+xml' data={LoaderIcon} width={60} height={60}>
      </object>
      <span className={styles.label}>Menu🧘🏽loading</span>
    </div>
  );
};

export default Loader;
