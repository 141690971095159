import clsx from 'clsx';
import React from 'react';
// import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import styles from './styles.module.scss';
import { iFeaturesItem } from './types';

const FeaturesItem = ({
  img,
  title,
  description,
  linkTitle,
  linkPath,
  className = '',
}: iFeaturesItem) => {
  return (
    <div
      className={clsx({
        [styles.featuresItem]: true,
        [className]: Boolean(className),
      })}
    >
      <img src={img} alt='' className={styles.image}/>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
      {(linkTitle && linkPath) && <HashLink to={linkPath} className={styles.link}>{linkTitle}</HashLink>}
    </div>
  );
};

export default FeaturesItem;
