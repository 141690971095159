import React from 'react';
import { REVIEWS } from '../../../utils/constants';
import DefaultButton from '../../Common/DefaultButton';
import { iRateUsFinalLove } from '../types';
import Love from './../../../assets/images/love.png';
import StarFilled from './../../../assets/svg/starFilled.svg';

import styles from './styles.module.scss';

const RateUsFinalLove = ({ menuData }: iRateUsFinalLove) => {

  const stars = [
    StarFilled,
    StarFilled,
    StarFilled,
    StarFilled,
    StarFilled,
  ]


  return (
    <>
      <div className={styles.titleSad}>
        <img src={Love} alt='Sad' />
        <div>
          <span>Thank you!</span>
          <span className={styles.label}>
            Help us to make more happy foodies in this world! Leave a review!
          </span>
        </div>
      </div>
      <div className={styles.rating}>
        {stars.map((star, index) => (
          <img
            src={star}
            key={index}
            alt='Star'
          />
        ))}
      </div>
      <div className={styles.reviews}>
        {menuData.reviewLinks.map((review) => {
          const reviewItem = REVIEWS[review.type];
          return (
            reviewItem && (
              <a href={review.link} className={styles.reviewLink} key={review.type}>
                <DefaultButton text={reviewItem.title} secondary svg={reviewItem.icon} />
              </a>
            )
          );
        })}
      </div>
    </>
  );
};

export default RateUsFinalLove;
