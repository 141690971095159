import React from 'react';
import clsx from 'clsx';
import { iDefaultButton } from './types';

import styles from './styles.module.scss';

function DefaultButton({
  text,
  svg,
  svgPosition,
  onClick = () => {},
  secondary,
  className = '',
  SvgElement,
}: iDefaultButton) {
  // const svgBlock = Svg && svgPosition && (
  //   <img src={svg} alt={text} className={styles.svg} />
  // );
  // const hoverSvgBlock = hoverSvg && svgPosition && (
  //   <img src={svg} alt={text} className={styles.hoverSvg} />
  // );

  const getSvg = (
    svg: any,
    svgPosition: any,
    SvgElement: any,
    className: string
  ) => {
    if (!svg && !svgPosition) return null;

    if (SvgElement)
      return (
        <SvgElement
          className={clsx({
            [styles.hoverSvg]: true,
            [className]: true,
          })}
        />
      );

    return <img src={svg} alt={text} className={className} />;
  };

  return (
    <button
      className={clsx({
        [styles.defaultButton]: !secondary,
        [styles.secondaryButton]: secondary,
        [className]: className,
      })}
      onClick={onClick}
    >
      {svgPosition === 'start' &&
        getSvg(svg, svgPosition, SvgElement, styles.svgStart)}
      <span>{text}</span>
      {(!svgPosition || svgPosition === 'end') &&
        getSvg(svg, svgPosition, SvgElement, styles.svgEnd)}
    </button>
  );
}

export default DefaultButton;
