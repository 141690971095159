import React from 'react';
import Logo from './../../../assets/images/logo.png';
// import Instagram from './../../..//assets/svg/footer/instagram.svg';
// import Facebook from './../../../assets/svg/footer/fb.svg';
// import Twitter from './../../../assets/svg/footer/twitter.svg';
// import Vk from './../../../assets/svg/footer/vk.svg';

import styles from './styles.module.scss';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className={styles.footer} id="contacts">
      <div className={styles.left}>
        <div className={styles.logo}>
          <img src={Logo} alt='' />
        </div>
        <div className={styles.description}>
          Nova menu - Create the next generation of online menu in just 15
          minutes
        </div>
      </div>
      <div className={styles.center}>
        {/* <div className={styles.socials}>
          <a href=''>
            <img src={Twitter} alt='' />
          </a>
          <a href=''>
            <img src={Instagram} alt='' />
          </a>
          <a href=''>
            <img src={Vk} alt='' />
          </a>
          <a href=''>
            <img src={Facebook} alt='' />
          </a>
        </div> */}
        <span className={styles.contact}>Contact us:</span>
        <a
          href='mailto: maria@nova-menu.com'
          target='_blank'
          rel='noreferrer'
          className={styles.email}
        >
          maria@nova-menu.com
        </a>
        <span className={styles.copyright}>With ❤️&🧘🏽from 🇺🇦🇬🇪🇧🇾. © 2022 Nova Menu</span>
      </div>
      <div className={styles.right}>
        <HashLink
          to='/terms-of-use#intro'
          className={styles.link}
        >
          Terms Of Use
        </HashLink>
        <HashLink
          to='/privacy-policy#intro'
          className={styles.link}
        >
          Privacy Policy
        </HashLink>
        <HashLink to='/believe#supportUs' className={styles.link}>
          Support Us
        </HashLink>
      </div>
    </div>
  );
};

export default Footer;
