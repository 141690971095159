import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import ModalStore from '../../../stores/ModalStore';
import DefaultButton from '../../Common/DefaultButton';
import LinkButton from '../../Common/LinkButton';
import IntroImage from './../../../assets/images/intro.png';
import { ReactComponent as Forward } from './../../../assets/svg/forward-white.svg';

import styles from './styles.module.scss';

const Intro = observer(() => {

  const { openModal } = ModalStore;

  return (
    <div className={styles.intro}>
      <div className={styles.content}>
        <h1 className={styles.title}>
          Contactless <br /> QR menu & feedback online
        </h1>
        <span className={styles.subtitle}>
          Create the next generation of online menu in just 15 minutes
        </span>
        <DefaultButton
          text='Try it 1-month Free'
          className={styles.tryFree}
          SvgElement={Forward}
          svgPosition='end'
          onClick={openModal}
        />
        <Link to='/uj/menu' className={styles.example}>
            <LinkButton
              text='See Online Menu example'
            />
          </Link>
      </div>
      <div className={styles.image}>
        <img src={IntroImage} alt='' />
      </div>
    </div>
  );
});

export default Intro;
