import clsx from 'clsx';
import React from 'react';

import styles from './styles.module.scss';
import { iPlacesItem } from './types';

const PlacesItem = ({ img, text, className }: iPlacesItem) => {
  return (
    <div
      className={clsx({
        [styles.placesItem]: true,
        [className]: true,
      })}
    >
      <div className={styles.image}>
        <img src={img} alt='' />
      </div>
      <div className={styles.text}>{text}</div>
    </div>
  );
};

export default PlacesItem;
