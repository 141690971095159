import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { iMobileMenu } from '../types';
import Close from './../../../../assets/svg/close.svg';
import { HEADER_NAV } from '../../../../utils/constants';

import styles from './styles.module.scss';
import DefaultButton from '../../DefaultButton';

const MobileMenu = ({ isOpen, isOpenHandler }: iMobileMenu) => {
  return (
    <div
      className={clsx({
        [styles.mobileMenu]: true,
        [styles.opened]: isOpen,
      })}
    >
      <div className={styles.close} onClick={isOpenHandler}>
        <img src={Close} alt='' />
      </div>
      <div className={styles.content}>
        {HEADER_NAV.map((item) =>
          item.isHashLink ? (
            <HashLink
              key={item.path}
              to={item.path}
              className={styles.navItem}
              onClick={isOpenHandler}
            >
              {item.title}
            </HashLink>
          ) : (
            <Link
              to={item.path}
              className={styles.navItem}
              onClick={isOpenHandler}
              key={item.path}
            >
              {item.title}
            </Link>
          )
        )}
        <div className={styles.buttons}>
          <Link to='' className={styles.login} onClick={isOpenHandler}>
            <DefaultButton text='Login' />
          </Link>
          <Link to='' className={styles.signUp} onClick={isOpenHandler}>
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
