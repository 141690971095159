import React, { useState } from 'react';
import { RATE_STAGES } from '../../../utils/constants';
import { iRateUs } from '../types';
import RateUsDefault from './RateUsDefault';
import RateUsFinalLove from './RateUsFinalLove';
import RateUsFinalSad from './RateUsFinalSad';
import RateUsSad from './RateUsSad';

const RateUs = ({ menuData }: iRateUs) => {
  const [leaveFeedback, setLeaveFeedback] = useState(false);
  const [stage, setStage] = useState(RATE_STAGES.START);

  const toggleLeaveFeedback = () => {
    setLeaveFeedback((prev) => !prev);
  };

  const onStage = (stage: string) => {
    setStage(stage);
  };
  

  switch (stage) {
    case RATE_STAGES.START:
      return <RateUsDefault onStage={onStage} />;
    case RATE_STAGES.SAD_FORM:
      return (
        <RateUsSad
          leaveFeedback={leaveFeedback}
          toggleLeaveFeedback={toggleLeaveFeedback}
          onStage={onStage}
        />
      );
    case RATE_STAGES.SAD_FINAL:
      return <RateUsFinalSad />;
    case RATE_STAGES.LOVE_FINAL:
      return <RateUsFinalLove menuData={menuData} />;
    default:
      return <RateUsDefault onStage={onStage} />;
  }
};

export default RateUs;
