import React from 'react';
import FeaturesItem from '../FeaturesItem';
import { BENEFITS_ITEMS } from '../../../utils/landingContent';
import BenefitsBG from './../../../assets/images/benefits-bg.png';
import ForwardIcon from './../../../assets/svg/forward.svg';

import styles from './styles.module.scss';
import LinkButton from '../../Common/LinkButton';
import { HashLink } from 'react-router-hash-link';

const Benefits = () => {
  return (
    <div className={styles.benefits}>
      <div className={styles.background}>
        <img src={BenefitsBG} alt='' />
      </div>
      <div className={styles.content}>
        <h3 className={styles.title}>Faultless benefits at your service</h3>
        <div className={styles.benefitsItems}>
          {BENEFITS_ITEMS.map((item, index) => (
            <FeaturesItem
              {...item}
              key={index}
              className={styles.benefitsItem}
            />
          ))}
        </div>
        <HashLink to='#features' smooth className={styles.link}>
          <LinkButton
            text='See all features shaped for you'
            className={styles.allFeatures}
            svg={ForwardIcon}
          />
        </HashLink>
      </div>
    </div>
  );
};

export default Benefits;
