import React, { useState } from 'react';
import { RATE_STAGES } from '../../../utils/constants';
import DefaultButton from '../../Common/DefaultButton';
import Input from '../../Common/Input';
import { iRateUsSad } from '../types';
import Sad from './../../../assets/images/sad.png';

import styles from './styles.module.scss';

const RateUsSad = ({
  toggleLeaveFeedback,
  leaveFeedback,
  onStage,
}: iRateUsSad) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('+');

  const onName = (e: React.ChangeEvent<HTMLInputElement>) =>
    setName(e.target.value);
  const onEmail = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);
  const onPhone = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPhone(e.target.value);

  const feedbackForm = (
    <>
      <span className={styles.label}>What’s your name</span>
      <Input value={name} onChange={onName} />
      <span className={styles.label}>Your email</span>
      <Input value={email} onChange={onEmail} type="email"/>
      <span className={styles.label}>Your phone number</span>
      <Input value={phone} onChange={onPhone} type="phone"/>
    </>
  );

  return (
    <>
      <div className={styles.titleSad}>
        <img src={Sad} alt='Sad' />
        <span>Ohh... Please tell us how we can improve</span>
      </div>
      <span className={styles.subtitle}>
        Let us know your concerns, so we can address them straight away
      </span>
      <textarea className={styles.tellMe} placeholder='What can we make better?' />
      <div className={styles.feedback}>
        <span className={styles.feedbackLabel}>
          May we follow you up on your feedback?
        </span>
        <label className={styles.switchContainer}>
          <input
            type='checkbox'
            checked={leaveFeedback}
            onChange={toggleLeaveFeedback}
          />
          <span className={styles.switch} />
          <div className={styles.border}></div>
        </label>
      </div>
      {leaveFeedback && feedbackForm}
      <DefaultButton
        text='Send'
        onClick={() => onStage(RATE_STAGES.SAD_FINAL)}
      />
      <div className={styles.devider}> </div>
      <DefaultButton
        text='Cancel'
        secondary
        onClick={() => onStage(RATE_STAGES.START)}
      />
    </>
  );
};

export default RateUsSad;
