import React from 'react';
import Footer from '../../components/Common/Footer';
import Header from '../../components/Common/Header';

const PrivacyPolicyPage = () => {
  return (
    <div style={{ backgroundColor: '#edf0f6' }} id="intro">
      <div style={{ maxWidth: 1200, margin: '0 auto' }}>
        <Header />
        <div style={{ padding: '90px 0' }}>
          <h1>
            <strong>Privacy Policy</strong>
          </h1>
          <h1>
            <strong>PERSONAL DATA PROCESSING PRINCIPLES Nova</strong>
          </h1>
          <h2>
            <strong>1. Basic provisions</strong>
          </h2>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                1.1. These personal data processing principles (hereinafter
                “Principles“) govern personal data processing during the use of
                the Nova Application services (hereinafter “Nova Application“)
                by its users (hereinafter “User“ or “you“).
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                1.2. The owner and operator of the Nova Application, and the
                Personal Data Controller in the sense of Article 4 paragraph 7
                of Regulation (EU) 2016/679 of the European Parliament and of
                the Council, on the protection of natural persons with regard to
                the processing of personal data and on the free movement of such
                data (hereinafter “GDPR“) is Nova QR s.r.o., Identification
                Number: 09517600, registered office Rybná 716/24, Staré Město,
                110 00 Prague 1, entered in the Commercial Register maintained
                by the Municipal Court in Prague, file reference C 337491
                (hereinafter “Controller“ or “we“).
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                1.3. Please read how we collect, process and protect your
                personal data (this means any information relating to a directly
                or indirectly identified or identifiable natural person), if you
                use the Nova Application services. This document contains
                information about your rights.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                1.4. The Controller’s contact details are as follows:
              </span>
            </li>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  delivery address: Rybná 716/24, Staré Město, 110 00 Prague 1;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  contact e-mail address:{' '}
                </span>
                <a href='mailto:maria@nova-menu.com'>
                  <span style={{ fontWeight: 400 }}>maria@nova-menu.com</span>
                </a>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  telephone: +380632503698
                </span>
              </li>
            </ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                1.5. The Controller has not appointed a Data Protection Officer.
              </span>
            </li>
          </ul>
          <h2>
            <strong>2. Definition of personal data</strong>
          </h2>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                2.1. The Controller processes only personal data that the User
                themselves provided to them. The User declares that all personal
                data provided by the User to the Controller is truthful,
                accurate, up-to-date, correct and complete. The Controller does
                not assume any obligation to verify this information. If you
                provide any information that is false, inaccurate, out of date
                or incomplete, or if we have reasonable suspicion that the
                information is false, inaccurate, out of date or incomplete,
                then we are entitled to suspend or terminate our collaboration,
                and deny all current or future use of the Nova Application
                services (or any part thereof).
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                2.2. In connection with your use of the Nova Application
                services, the Controller processes the following personal data:
              </span>
            </li>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  identification details (in particular name, surname, username
                  and password);
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  contact details (in particular e-mail address and telephone
                  number);
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  payment details (in particular order payment information);
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  delivery details (in particular delivery address);
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  other information that you provide to us in connection with
                  the use of the Nova Application services, or that we obtain in
                  this context (information about your consumer behaviour etc.).
                </span>
              </li>
            </ul>
          </ul>
          <h2>
            <strong>
              3. Conditions and purpose of the personal data processing
            </strong>
          </h2>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                3.1. The processing of personal data by the Controller is
                lawful, as at least one of the following conditions is always
                fulfilled during the processing:
              </span>
            </li>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  the User granted their consent to the processing of their
                  personal data as per Article 6 paragraph 1 section a) of the
                  GDPR for one or more specific purposes:
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  the processing of the User’s personal data is necessary for
                  the fulfilment arising from the Contract to which the User is
                  a party, or the implementation of measures taken before the
                  conclusion of the Contract at this User’s request as per
                  Article 6 paragraph 1 section b) of the GDPR;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  the processing of the User’s personal data is necessary for
                  the fulfilment of the legal obligations that applies to the
                  Controller; or
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  the processing of the User’s personal data is necessary for
                  the purposes of the Controller’s legitimate interests as per
                  Article 6 paragraph 1 section f) of the GDPR.
                </span>
              </li>
            </ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                3.2 In connection with the use of the Nova Application services,
                the Controller processes Users‘ personal data for the following
                purposes:
              </span>
            </li>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  processing based on the necessity of such processing for the
                  separate fulfilment of the Contract concluded in connection
                  with the provision of services via the Nova Application;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  processing based on the fulfilment of our legal obligations
                  for the purposes of fulfilling these legal obligations;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  processing due to our legitimate interest, where this reason
                  leads, in particular, to personal data processing for the
                  purpose of direct marketing or for statistical and analytical
                  purposes, for the storage of certain information for the
                  purposes of the possible enforcement of ownership claims;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  processing based on your consent, if it was provided to the
                  Controller, where personal data is processed for marketing and
                  advertising purposes on the basis of this consent.
                </span>
              </li>
            </ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                3.3 The personal data is processed for the period absolutely
                necessary with regard to the purpose of its processing, or for
                the period specified in the consent to the personal data
                processing.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                3.4 With regard to the actual provision of services via the Nova
                Application, personal data is provided to the individual
                business partners whose services are mediated by the Nova
                Application.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                3.5 With regard to the legal obligation to perform mandatory
                sanction and AML monitoring (anti-money laundering measures) of
                transactions executed via the Nova Application, personal data
                may be provided to Stripe ( stripe.com ), as the operator of the
                payment gateway in the Nova Application.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                3.6 Based on the provided consent, personal data may be passed
                on, for advertising and marketing purposes, to the entities
                specified in the consent to the personal data processing.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                3.7 Personal data is also provided, on the basis of legal
                regulations, to the entities specified in these legal
                regulations.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                3.8 Your personal data is processed both manually and
                automatically, and is stored in both paper and electronic form.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                3.9 We are committed to storing your data securely. For this
                reason, we have implemented appropriate physical, technical and
                organisational measures, as well as plans to protect and secure
                the data that we received form you (which however does not free
                you from the obligation to take appropriate steps to secure your
                data, particularly during data transfers). The aim is to prevent
                the unlawful or unauthorised processing of your personal data,
                or the random, unauthorised or unlawful disclosure, use,
                transfer, processing, copying, transmission, modification, loss
                or damage of your data. Despite all efforts to abide by the
                rules stipulated in the relevant legal regulations, it is not
                possible to ensure the security of your data if it is
                transmitted in an unsecured manner.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                3.10 If you have chosen a password that shall allow you access
                to certain parts of the Nova Application, then you are
                responsible for keeping this password confidential. We ask you
                not to disclose this password to anyone.
              </span>
            </li>
          </ul>
          <h2>
            <strong>4. Rights of the User as the data subject</strong>
          </h2>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                4.1 Based on the rules set forth in the GDPR, the User has, in
                particular, the right:
              </span>
            </li>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  to access their personal data;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  to the correction of the personal data, or a restriction of
                  its processing;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  to the deletion of the personal data;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  to raise an objection against the personal data processing;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  to the portability of their personal data;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  to revoke their consent to the personal data processing;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  to file a complaint with the Office for Personal Data
                  Protection, if they believe that the processing breached their
                  right to the protection of the personal data during its
                  processing, or a related legal regulation.
                </span>
              </li>
            </ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                4.2 To exercise any of these rights, the User can contact the
                Controller using the afore-mentioned contact details. The
                Controller shall advise or assist Users with the exercise of
                their rights. You also have the right to file a complaint with
                the supervisory body, the Office for Data Protection of the
                Ukraine. You can learn more at{' '}
              </span>
              <a href='https://www.uoou.cz/?roistat_visit=242527'>
                <span style={{ fontWeight: 400 }}>https://www.uoou.cz.</span>
              </a>
            </li>
          </ul>
          <h2>
            <strong>5. Final provision</strong>
          </h2>
          <p>
            <span style={{ fontWeight: 400 }}>
              5.1 By checking the consent box on the relevant form and/or active
              use of the Nova Application or related services, the User confirms
              that they have been familiarised with these Principles, and that
              they accept them in full. 5.2 These Principles are governed by the
              law of the Ukraine. The general courts of the Ukraine are
              authorised to arbitrate related legal disputes between the
              Controller and the User. 5.3 The Controller is entitled to
              unilaterally amend the wording of these Principles if necessary.
              The Controller shall always announce the amendment before it comes
              into effect, by displaying an announcement in the Nova Application
              and/or sending a notification to the User’s e-mail address. 5.4
              These Principles come into effect on 26/03/2020.
            </span>
          </p>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
