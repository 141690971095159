import React from 'react';
import { WORKDAYS } from '../../../utils/constants';
import { iWorkdays } from '../types';

import styles from './styles.module.scss';

const Workdays = ({ workdays, isVisible }: iWorkdays) => {
  const sortedWorkdays = Object.entries(workdays)
    .map(([key, value]) => {
      const dayIndex = WORKDAYS.findIndex((day) => day.key === key);
      return {
        title: WORKDAYS[dayIndex].title,
        from: value.from,
        to: value.to,
        index: dayIndex,
      };
    })
    .sort((a, b) => a.index - b.index);

  if (!isVisible) return null;

  return (
    <div className={styles.workdays}>
      {sortedWorkdays.map((day) => {
        return (
          <div className={styles.workdaysItem} key={day.title}>
            <span className={styles.day}>{day.title}</span>
            <span className={styles.hours}>{`${day.from} - ${day.to}`}</span>
          </div>
        );
      })}
    </div>
  );
};

export default Workdays;
