import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import Modal from 'react-modal';
import ModalStore from '../../../stores/ModalStore';
import Close from './../../../assets/svg/close.svg';

import styles from './styles.module.scss';
import Input from '../Input';
import DefaultButton from '../DefaultButton';

Modal.setAppElement('#root');

const customStyles = {
  overlay: { zIndex: 1000 },
};

const ComingSoonModal = observer(() => {
  const { isOpen, closeModal, submitEmail } = ModalStore;

  const [email, setEmail] = useState('');
  const [finalStep, setFinalStep] = useState(false);

  const onEmail = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);

  const onFinalStep = () => {
    submitEmail(email);
    setFinalStep(true);
  };

  return (
    <Modal
      isOpen={isOpen}
      className={styles.comingSoonModal}
      style={customStyles}
      onRequestClose={closeModal}
    >
      <form onSubmit={e => e.preventDefault()} className={styles.form}>
        <img src={Close} alt='' className={styles.close} onClick={closeModal} />
        <span className={styles.title}>
          {finalStep ? 'Thank you for you interest!❤️' : 'Create an account'}
        </span>
        {finalStep ? (
          <span className={styles.thanks}>
            We will get back to you shortly via email!
          </span>
        ) : (
          <>
            <span className={styles.thanks}>
              Leave your email and we will create you Nova account in the next
              24 hours
            </span>
            <Input value={email} onChange={onEmail} placeholder='Your email' />
            <DefaultButton text='Next' onClick={onFinalStep} />
          </>
        )}
      </form>
    </Modal>
  );
});

export default ComingSoonModal;
