import React from 'react';
import Footer from '../../components/Common/Footer';
import Header from '../../components/Common/Header';

const TermsOfUsePage = () => {
  return (
    <div style={{ backgroundColor: '#edf0f6' }} id="intro">
      <div style={{ maxWidth: 1200, margin: '0 auto' }}>
        <Header />
        <div style={{ padding: '90px 0' }}>
          <h1>
            <strong>Terms of Use</strong>
          </h1>
          <h1>
            <strong>TERMS OF USE OF NOVA MENU SERVICES FOR PARTNERS</strong>
          </h1>
          <h2>
            <strong>1. INTRODUCTORY PROVISIONS AND DEFINITIONS</strong>
          </h2>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                1.1. These Terms of Use of Nova Application services
                (hereinafter{' '}
              </span>
              <strong>“Terms“</strong>
              <span style={{ fontWeight: 400 }}>) are issued by </span>
              <strong>Nova Menu.</strong>
              <span style={{ fontWeight: 400 }}>
                , Identification Number: 09517600, registered office Rybná
                716/24, Staré Město, 110 00 Prague 1, entered in the Commercial
                Register maintained by the Municipal Court in Prague, file
                reference C 337491 (hereinafter{' '}
              </span>
              <strong>“Operator“</strong>
              <span style={{ fontWeight: 400 }}>).</span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                1.2. These Terms govern the mutual rights and obligations
                between the Operator and the Partner during the mediation of
                Brokered Agreements via the Nova Application, as well as the
                Contracting Parties‘ other rights and obligations. These Terms
                are an integral part of the Contract, and are binding for the
                Partner upon Registration.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                1.3. Unless the context indicates otherwise, the following words
                and expressions used in these Terms have the following meaning:
              </span>
            </li>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>1.3.1. </span>
                <strong>“Nova Application“</strong>
                <span style={{ fontWeight: 400 }}>
                  {' '}
                  is a technical solution, or more precisely a computer
                  programme or application called Nova, whose main essence and
                  function is to enable Users to browse, order and purchase
                  Products from the Operator’s Partners, and to broker the
                  conclusion of a Purchase Contract regarding the relevant
                  Products with the User, or to broker delivery services.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>1.3.2. </span>
                <strong>“Copyright Act“</strong>
                <span style={{ fontWeight: 400 }}>
                  {' '}
                  is Act no. 121/2000 Coll., on Copyright and Related Rights and
                  on Amendment of Certain Acts, as amended.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>1.3.3. </span>
                <strong>“Price List“</strong>
                <span style={{ fontWeight: 400 }}>
                  {' '}
                  is the Operator’s Price List, which forms an Appendix to these
                  Terms and an integral part of the Contract with the Partner.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>1.3.4. </span>
                <strong>“Partner“</strong>
                <span style={{ fontWeight: 400 }}>
                  {' '}
                  is the operator of a restaurant or other business providing
                  catering services, or another partner (natural or legal person
                  engaged in business) whose Products are offered and sold via
                  the Nova Application on the basis of the Contract.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>1.3.5. </span>
                <strong>“Terms“</strong>
                <span style={{ fontWeight: 400 }}>
                  {' '}
                  are these Terms of Use of Nova Application services.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>1.3.6. </span>
                <strong>“Selling Price“</strong>
                <span style={{ fontWeight: 400 }}>
                  {' '}
                  is the price of the Partner’s Products (including VAT)
                  purchased by the User on the basis of a Brokered Agreement as
                  per the information recorded by the Nova Application.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>1.3.7. </span>
                <strong>“Products“</strong>
                <span style={{ fontWeight: 400 }}>
                  {' '}
                  are the Partner’s products and services that are offered and
                  sold by the Partner, via the Nova Application, to Users for
                  the purpose of direct and immediate consumption on the basis
                  of a Brokered Agreement.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>1.3.8. </span>
                <strong>“Operator“</strong>
                <span style={{ fontWeight: 400 }}>
                  {' '}
                  is the company defined in Article 1.1 of these Terms.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>1.3.9. </span>
                <strong>“Civil Code“</strong>
                <span style={{ fontWeight: 400 }}>
                  {' '}
                  is Act no. 89/2012 Coll., of the Civil Code, as amended.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>1.3.10. </span>
                <strong>“Registration“</strong>
                <span style={{ fontWeight: 400 }}>
                  {' '}
                  is the Partner’s electronic registration in the Nova
                  Application, properly performed by the entry of at least the
                  mandatory registration details, and their subsequent storage
                  in the Nova Application.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>1.3.11. </span>
                <strong>“Services“</strong>
                <span style={{ fontWeight: 400 }}>
                  {' '}
                  mean the Operator’s activity for the Partner aimed at the
                  Partner concluding Brokered Agreements with Users via the Nova
                  Application or by other means, i.e. primarily consisting of
                  (i) brokering the conclusion of purchase or similar contracts
                  for Products, (ii) offering and presentation of Products from
                  the Partner’s current menu to third parties, for the prices as
                  per the Partner’s current Price List, (iii) mediation of
                  communication between the Partner and the User, all via the
                  Nova Application.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>1.3.12. </span>
                <strong>“Contract“</strong>
                <span style={{ fontWeight: 400 }}>
                  {' '}
                  is a contract concluded between the Operator and the Partner,
                  whose subject is primarily the provision of Services to the
                  Partner by the Operator, and the Partner’s undertaking to pay
                  the Operator the agreed remuneration, as well as the related
                  rights and obligations of both Contracting Parties.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>1.3.13. </span>
                <strong>“User“</strong>
                <span style={{ fontWeight: 400 }}>
                  {' '}
                  is a natural or legal person who uses the Nova Application
                  services, thereby concluding a Brokered Agreement with the
                  Partner.
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>1.3.14. </span>
                <strong>“Brokered Agreement“</strong>
                <span style={{ fontWeight: 400 }}>
                  {' '}
                  is a purchase or other contract concluded between the Partner
                  and the User via the Nova Application in relation to Products.
                </span>
              </li>
            </ul>
          </ul>
          <h2>
            <strong>2. CONCLUSION AND SUBJECT OF THE CONTRACT</strong>
          </h2>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                2.1. The Partner’s Registration in the Nova Application
                constitutes a binding offer by the Partner to conclude a
                Contract with the Operator. By sending the Registration, the
                Partner expresses their agreement with the current versions of
                the Terms and the Price List.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                2.2. If the Registration is approved, then the Operator sends
                the Partner a confirmation to the Partner’s e-mail address set
                forth in the Registration; the delivery of this confirmation to
                the Partner constitutes the conclusion of the Contract. The
                Contract is concluded for an indefinite period.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                2.3. The Contract can also be concluded in ways other than via
                the Registration.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                2.4. A provision in the Contract takes precedence over
                provisions in the Terms.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                2.5. On the basis of the Contract, the Operator undertakes to
                provide Services to the Partner, and the Partner undertakes to
                pay the Operator the remuneration properly and on time, as well
                as to fulfil the other obligations stipulated in the Contract.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                2.6. The Partner explicitly acknowledges, and agrees with, the
                fact that the Operator does not guarantee the Partner any number
                of Brokered Agreements.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                2.7. The Operator’s brokerage activity is non-exclusive, and the
                Operator is therefore entitled to also provide Services
                consisting of brokerage via the Nova Application or in other
                ways to other parties. The Operator also has the right to
                represent, or more precisely be active for the benefit of the
                User, without this fact precluding the Operator’s entitlement to
                remuneration for the provision of the Services.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                2.8. The Operator makes the Nova Application, or a relevant part
                thereof, available to the Partner for the purpose of managing
                the Partner’s profile and their menu of Products, including
                their prices and other information. The Partner is responsible
                for including information regarding allergens in their menu in
                the Nova Application. In addition to the designation of the
                Product and its price, the Product presentation in the Nova
                Application also contains information about the cost of
                packaging and delivering the Product, which the User is obliged
                to pay. The presentation can also contain a more detailed
                description of the Product, its properties, sizes and
                availability, and possibly also a photographic illustration of
                same. The Partner is responsible for the correctness,
                up-to-dateness and legal flawlessness of the information
                published in the Nova Application. The Operator reserves the
                right to remove a Product from the Partner’s menu in the Nova
                Application without notifying the Partner in advance.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                2.9. The Operator can decide, at their own discretion, whether
                and in what order they display and present the Partner’s menu in
                the Nova Application.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                2.10. The Partner undertakes to provide the Operator with the
                necessary information relating to Brokered Agreements.
              </span>
            </li>
          </ul>
          <h2>
            <strong>3. Nova APPLICATION SERVICES</strong>
          </h2>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                3.1. The Operator offers the following services via the Nova
                Application:
              </span>
            </li>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  3.1.1. Nova Take Away, consisting of Users having the option
                  to order the Partner’s Products via the Nova Application to
                  take away, i.e. with the option of personal collection or
                  delivery to a designated location by the Partner, or via a
                  delivery service (distribution).
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  3.1.2. Nova QR, consisting of the Operator enabling the
                  Partner to offer Users the option of opening the Partner’s
                  current Product menu in the Nova Application by scanning the
                  QR code located in the Partner’s premises; after scanning the
                  QR code, the User will be redirected to the Partner’s current
                  Product menu in the Nova Application, and they will have the
                  option of ordering and/or paying for the Partner’s products.
                  In this context, the Operator will supply the Partner with the
                  relevant QR codes, and enable the Partner to use these QR
                  codes; the Partner will place these QR codes in their
                  premises.
                </span>
              </li>
            </ul>
          </ul>
          <h2>
            <strong>4. BROKERED AGREEMENT</strong>
          </h2>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                4.1. If the Operator receives an order for the Partner’s Product
                from the User via the Nova Application, then the Operator will
                forward this order to the Partner via the Nova Application, or
                by other means of electronic communication.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                4.2. The Partner either confirms or rejects the order for the
                Product from the User. The delivery of a confirmation of the
                order to the User via the Nova Application constitutes the
                conclusion of a Brokered Agreement between the Partner and the
                User.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                4.3. Under a Brokered Agreement, the Partner undertakes to
                supply Products to the User properly and on time, and the User
                undertakes to take receipt of the ordered Products from the
                Partner and pay the Selling Price for them, all at the delivery
                location as per the Brokered Agreement.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                4.4. The Contracting Parties to the Brokered Agreement are only
                the Partner and the User, not the Operator.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                4.5. Depending on the User’s Nova and on whether the given
                method is currently available to the User in the Nova
                Application, the Selling Price of the ordered Product can be
                paid by the User via the following payment methods:
              </span>
            </li>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  payment in advance, before delivery and receipt of the Product
                  by the User: cashless online payment by payment card via a
                  payment gateway;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  payment upon receipt of the Product by the User: (i) cash or
                  (ii) cashless payment by payment card in the Partner’s
                  premises upon receipt of the Product;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  another payment method offered to the User in the Nova
                  Application before the Product order is completed.
                </span>
              </li>
            </ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                4.6. The Partner explicitly authorises the Operator to perform
                the following activities on the Partner’s behalf in relation to
                the Brokered Agreement:
              </span>
            </li>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  collection of the paid Selling Price from the User on the
                  basis of the Brokered Agreement, if the Selling Price is paid
                  in the form of a cashless online payment by payment card via a
                  payment gateway before the delivery and receipt of the ordered
                  Product, whereby this price is transferred by the User to the
                  Operator’s bank account;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  forwarding of the issued receipt for the Selling Price to the
                  User.
                </span>
              </li>
            </ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                4.7. Depending on the User’s Nova and on whether the given
                delivery method is currently available to the User in the Nova
                Application, the delivery of the ordered Product can be realised
                in the following ways:
              </span>
            </li>
            <ul>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  personal collection by the User, at their own expense, in the
                  Partner’s premises;
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  delivery by the Partner to the location in the Partner’s
                  premises (to the desk) that the User stated in the order (by
                  scanning the QR code on the desk in the Partner’s premises);
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  delivery to the location that the User stated in the order as
                  the delivery location, by the Partner or using a delivery
                  service (in that case, the transport costs can be charged to
                  the User);
                </span>
              </li>
              <li style={{ fontWeight: 400 }}>
                <span style={{ fontWeight: 400 }}>
                  other methods offered to the User in the Nova Application
                  before the Product order is completed.
                </span>
              </li>
            </ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                4.8. The Partner’s rights and obligations in relation to the
                Operator and the User are further defined in the Terms of Use of
                the Nova Application for the User, which are available in the
                Nova Application (hereinafter “Terms for the User“), and by
                which the Partner is also bound in the parts that relate to
                them.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                4.9. In relation to the Brokered Agreement, the Partner is
                exclusively responsible to the User for compliance with the
                relevant legal regulations that govern relationships with
                consumers arising on the basis of consumer contracts. In this
                regard, the Partner is obliged to familiarise themselves with
                the wording of the Terms for the User, and subsequently notify
                the Operator if they find any provision in the Brokered
                Agreement that is in conflict with the relevant legal
                regulations that govern relationships with consumers arising on
                the basis of consumer contracts.
              </span>
            </li>
          </ul>
          <h2>
            <strong>5. OPERATOR’S REMUNERATION, AND PAYMENT TERMS</strong>
          </h2>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                5.1. The Operator is entitled to remuneration for the provision
                of the Services.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                5.2. The Operator’s remuneration consists of a monthly fee from
                the Partner for the use of the Nova Application services as per
                Article 3 of these Terms, whose amount is stipulated in the
                Price List (hereinafter “Monthly Fee“).
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                5.3. The Monthly Fee is always charged for the calendar month in
                advance, unless the Operator and the Partner agree otherwise.
                The Operator shall issue a tax document (invoice) to the Partner
                for a sum corresponding to the amount of the Monthly Fee, always
                on the first month of the relevant calendar month for which the
                Monthly Fee is paid; VAT at the rate as per the relevant legal
                regulations will be added to this amount. The due payment date
                of the invoice is 14 (fourteen) calendar days from the date of
                issue.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                5.4. The Contracting Parties can agree in writing on individual
                price and payment terms. In that case, the agreed individual
                terms take precedence over the Price List.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                5.5 The Operator is entitled to unilaterally amend the Price
                List. The Operator will notify the Partner of the amendment to
                the Price List before it comes into effect, including the date
                of effectiveness of the amendment, at least 14 (fourteen)
                calendar days before the amendment comes into effect. If the
                Partner does not agree with the amendment to the Price List,
                then they have the right to notify the Operator in writing that
                they do not consent to the new version of the Price List, and
                withdraw from the Contract in writing for this reason, no later
                than the time the new Price List comes into effect. In that
                case, the withdrawal from the Contract is effective the day that
                the new Price List comes into effect. If the Partner does not
                withdraw from the Contract as per the previous provisions, then
                they are bound by the new Price List from the first day of its
                effectiveness.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                5.6. In addition to the Monthly Fee, the Operator is entitled to
                stipulate and request other remuneration for activities
                performed by the Operator on the basis of or in connection with
                the Contract, primarily for services involving support for and
                maintenance of the Nova Application, integration etc.
              </span>
            </li>
          </ul>
          <h2>
            <strong>6. GRANTING OF LICENCE</strong>
          </h2>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                6.1. The Operator provides the Partner with a non-exclusive
                authorisation to exercise the right to use the Nova Application
                (hereinafter{' '}
              </span>
              <strong>“Licence“</strong>
              <span style={{ fontWeight: 400 }}>
                ), only for the purposes of the proper use of the Services, for
                the duration of the Contract.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                6.2. The remuneration for the provision of the Licence is
                included in the remuneration for the Services as per Article 5
                of the Terms.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                6.3. Under no circumstances does the Partner have the right to
                grant any third party a sublicence, or transfer the Licence to
                any other party, on the basis of a Licence granted in this way.
                The Partner also does not have the right to copy the Nova
                Application for the purpose of its distribution, distribute it,
                rent or lend it, or disclose it to third parties in any way.
              </span>
            </li>
          </ul>
          <h2>
            <strong>7. OTHER RIGHTS AND OBLIGATIONS</strong>
          </h2>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                7.1. The Partner undertakes to participate in the creation of
                the good reputation of the Nova Application services in relation
                to both Users and potential Users. In this context, they shall
                primarily realise the Brokered Agreement properly and on time,
                not reject Product orders from Users without a serious reason,
                settle any complaints by Users regarding Products properly and
                on time, not discourage Users from using the Nova Application
                services in any way, etc.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                7.2. The Operator is entitled to restrict or interrupt the
                functioning of the Nova Application, or access to same, for the
                period absolutely necessary for the maintenance or repair of the
                Nova Application, or for another reason on the part of the
                Operator or a third party. The Operator is also entitled to
                modify the technical solution of the Nova Application for
                operational or other reasons.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                7.3. The Operator is entitled to unilaterally offset, at any
                time, their payable and non-payable receivables due from the
                Partner (including receivables due to the price of services
                arising on a basis other than the Contract) against the
                Partner’s payable and non-payable receivables due from the
                Operator.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                7.4. The Partner is not entitled to offset any of their
                receivables due from the Operator against the Operator’s
                receivables due from the Partner without their written consent.
                The Partner is also not entitled to assign their receivables due
                from the Operator to a third party without the Operator’s
                written consent.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                7.5. The Contracting Parties undertake to maintain
                confidentiality in relation to classified information and all
                facts that constitute the Contracting Parties‘ trade secrets.
                Confidential information is considered, in particular, all
                information relating to the Contracting Parties that is subject
                to protection as per the regulations governing the area of
                intellectual property (e.g. information regarding information
                systems, know-how and individual components thereof).
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                7.6. The Partner explicitly consents to the processing, storage,
                use and downloading of data that is processed within the scope
                of the Nova Application by the Operator for the purpose of (i)
                internal use by the Operator and (ii) processing and publication
                in an aggregated or more precisely anonymised form (e.g. for the
                purposes of various studies including case studies, statistical
                reports, infographics etc.). The Partner also explicitly
                consents to this data being downloaded, processed and stored in
                the Operator’s database even after the end of the provision of
                the Services, including data that was obtained from appropriate
                sources after the termination of the Contract and before the
                disconnection of the sources that this data comes from. The
                Partner also acknowledges, and explicitly agrees with, the fact
                the personal data of the Partner or their representatives is
                processed by the Operator, as the Controller, in connection with
                the Provision of the Services.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                7.7. The Partner hereby grants their consent to the Operator to
                use the Partner’s name, logo and trade name (hereinafter{' '}
              </span>
              <strong>“Name“</strong>
              <span style={{ fontWeight: 400 }}>
                ) in all communication media for advertising purposes, primarily
                in the form of the promotion of the Nova Application and the
                Services. The Partner explicitly declares that they have the
                right to grant such consent. In cases where the Partner requires
                the Operator to abide by certain rules when using the Name (e.g.
                those contained in the graphic manual for the use of the given
                Name), the Partner must inform the Operator of these rules in
                writing, otherwise these rules will not be taken into
                consideration when using the Name. The payment for the use of
                the Name is included in the Operator’s remuneration. The Partner
                has the right to revoke their consent to the use of the Name, or
                restrict it via a written declaration delivered to the Operator.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                7.8. While using the Operator’s Services and the Nova
                Application, the Partner undertakes to comply with the
                applicable and effective legal regulations of the Ukraine and
                the European Union, in particular the Copyright Act. The Partner
                is obliged to compensate the Operator or third parties in full
                for all damage caused to them due to a breach of this
                obligation, that the Partner is responsible for.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                7.9. The Operator is entitled to deny the Partner access to the
                Nova Application services if the Partner breaches their
                obligations arising from this Contract and/or legal regulations.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                7.10. The Operator also reserves the right to restrict the
                provision of the Services, or terminate it without compensation,
                at any time and even without prior notification.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                7.11 The Partner acknowledges that the Operator provides them
                with the personal data of Users who use the Nova Application
                services, and conclude Brokered Agreements with the Partner, via
                the Nova Application. The Partner also acknowledges that they
                are in the position of Personal Data Controller in relation to
                Users‘ personal data, and undertakes to process this personal
                data in accordance with legal regulations relating to personal
                data processing and protection, in particular but not
                exclusively Regulation (EU) 2016/679 of the European Parliament
                and of the Council, of the 27th of April 2016, on the protection
                of natural persons with regard to the processing of personal
                data and on the free movement of such data, and repealing
                Directive 95/46/EC (General Data Protection Regulation – GDPR)
                and Act no. 110/2019 Coll., on Personal Data Processing, as
                amended.
              </span>
            </li>
          </ul>
          <h2>
            <strong>8. RESPONSIBILITY</strong>
          </h2>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                8.1. The Operator is not responsible for the fulfilment of the
                Brokered Agreement with the User. In this context, the Operator
                is not responsible for the quality or origin of the Products,
                and also bears no responsibility for payment for or delivery of
                the Products. The Operator bears no responsibility arising from
                the legal relationship between the Partner and the User. The
                responsibility for the resolution of all issues arising from
                Brokered Agreements concluded between the User and the Partner
                or in connection with them (including the settlement of any
                complaints) is born by the specific Partner who concluded the
                Brokered Agreement with the User.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                8.2. The Operator is not responsible for services provided by
                other entities, in particular payment system operators, payment
                methods, the quality and quantity thereof, or any consequences,
                or for the rights and obligations relating to these services
                etc. The Partner acknowledges that the Operator is not a
                provider of payment systems or portals.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                8.3. The Operator is not liable to the Partner or a third party
                for any lost profit. The Contracting Parties agree that the
                expected amount of actual damage from one damage event will not
                exceed, and is limited to, the amount paid for provided Services
                in the weekly billing period that preceded the weekly billing
                period in which the relevant damage event occurred.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                8.4. The Operator is not liable for damage caused by force
                majeure, e.g. in particular by natural disasters, natural, war
                or terrorist events etc.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                8.5. The Operator is not liable for damage caused by Nova
                Application outages or outages on the part of service providers,
                or for damage arising from actions that are necessary to ensure
                the operation of the Operator’s Services and the Nova
                Application (e.g. software updates, server configuration etc.).
              </span>
            </li>
          </ul>
          <h2>
            <strong>9. FINAL PROVISIONS</strong>
          </h2>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                9.1. All relationships arising from the Terms and the Contracts
                are governed by the Legal Code of the Ukraine. All disputes
                between the Contracting Parties, arising from the Contract or in
                connection with it, will be resolved by the general courts of
                the Ukraine.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                9.2. If any provision of these Terms is or becomes invalid or
                ineffective, then the other provisions of these Terms remain
                valid and effective. The invalid or ineffective provision will
                be replaced by another, valid and effective provision, whose
                content and meaning best corresponds to the content and meaning
                of the original provision.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                9.3. The Operator is entitled to unilaterally amend and
                supplement the wording of these Terms. The Operator will notify
                the Partner of the amendment to the Terms at least 14 (fourteen)
                days before it comes into effect, including the date of
                effectiveness of the amendment, by displaying a notification in
                the Nova Application or using the Partner’s e-mail address set
                forth in the Contract. If the Partner does not agree with the
                amendment to the Terms, then they have the right to notify the
                Operator in writing that they do not consent to the new version
                of the Terms, and withdraw from the Contract in writing for this
                reason, no later than the time the new Terms come into effect.
                In that case, the withdrawal from the Contract is effective the
                day that the new Terms come into effect. If the Partner does not
                withdraw from the Contract as per the previous provisions, then
                they are bound by the new Terms from the first day of their
                effectiveness.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                9.4. The Contracting Parties are entitled to withdraw from the
                Contract for any reason, or even without stating a reason. The
                notice of withdrawal must be in writing, and must be delivered
                to the other Contracting Party. The notice period is 3 (three)
                calendar months, and begins to run the first day of the calendar
                month following the month in which the notice was delivered to
                the other Contracting Party. In the event of a withdrawal from
                the Contract, the remuneration for the Services will be
                calculated in accordance with these Terms.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                9.5. The Contracting Parties are entitled to withdraw from the
                Contract in the event of a significant breach of same by the
                other Contracting Party.
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                9.6. These Terms become valid and effective on 25/09/2020
              </span>
            </li>
          </ul>
          <h3>
            <strong>Appendix no. 1 – Price List</strong>
          </h3>
          <h4>
            <strong>I. Monthly Fee</strong>
          </h4>
          <p>
            <span style={{ fontWeight: 400 }}>
              Nova Take Away and Digi menu
            </span>
          </p>
          <ul>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Monthly service - $9,99 US Dollars
              </span>
            </li>
            <li style={{ fontWeight: 400 }}>
              <span style={{ fontWeight: 400 }}>
                Yearly service - $99.99 US Dollars
              </span>
            </li>
          </ul>
          <p>&nbsp;</p>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default TermsOfUsePage;
