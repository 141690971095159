import React from 'react';
import { iInput } from './types';

import styles from './styles.module.scss';

const Input = ({ value, onChange, type, disabled, placeholder }: iInput) => {
  return (
    <input
      className={styles.input}
      value={value}
      onChange={onChange}
      type={type}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};

export default Input;
