import { observer } from 'mobx-react-lite';
import MenuStore from '../../../stores/MenuStore';
import DefaultLogo from './../../../assets/svg/defaultLogo.svg';
import styles from './styles.module.scss';

const MenuHeader = observer(() => {
  const { menuData } = MenuStore;

  return (
    <div className={styles.menuHeader}>
      <div className={styles.left}>
        <img className={styles.logo} src={DefaultLogo} alt='Logo' />
        <div className={styles.title}>{menuData?.name}</div>
      </div>
      <div className={styles.language}></div>
    </div>
  );
});

export default MenuHeader;
