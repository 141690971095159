import React from 'react'
import { PRICING_ITEMS } from '../../../utils/landingContent';
import PricingBG from './../../../assets/images/pricing-bg.png';
import PricingItem from './PricingItem';

import styles from './styles.module.scss';

const Pricing = () => {
  return (
    <div className={styles.pricing} id="pricing">
       <div className={styles.background}>
        <img src={PricingBG} alt='' />
      </div>
      <div className={styles.content}>
        <h3 className={styles.title}>Start Your QR Online Menu Today</h3>
        <div className={styles.pricingItems}>
          {
            PRICING_ITEMS.map(item => <PricingItem item={item} key={item.name}/>)
          }
        </div>
      </div>
    </div>
  )
}

export default Pricing;
