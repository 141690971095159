import { makeAutoObservable, runInAction } from 'mobx';
import { apiClient } from '../utils/apiClient';
import { iMenuData } from './types';

class MenuStore {
  menuData: iMenuData | null = null;
  isLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadMenuData = async (id: string) => {
    this.isLoading = true;
    try {
      const profileData = await apiClient.get(`/${id}/profile`);

      runInAction(() => {
        this.isLoading = false;
        this.menuData = profileData.data;
      });
    } catch (e) {
      this.isLoading = false;
    }
  };
}

export default new MenuStore();
