import React from 'react';
import Sad from './../../../assets/images/sad.png';

import styles from './styles.module.scss';

const RateUsFinalSad = () => {
  return (
    <div className={styles.titleSad}>
      <img src={Sad} alt='Sad' />
      <span>Thank you! <br/> We will become better for you!</span>
    </div>
  );
};

export default RateUsFinalSad;
