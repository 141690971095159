import { observer } from 'mobx-react-lite';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import DefaultButton from '../../components/Common/DefaultButton';
import Footer from '../../components/Common/Footer';
import Header from '../../components/Common/Header';
import Input from '../../components/Common/Input';
import FeaturesItem from '../../components/LandingCommon/FeaturesItem';
import ModalStore from '../../stores/ModalStore';
import { WE_BELIEVE_ITEMS } from '../../utils/landingContent';
import BG from './../../assets/images/benefits-bg.png';
import Bitcoin from './../../assets/images/bitcoin.png';
import Etherium from './../../assets/images/etherium.png';
import Copy from './../../assets/svg/copy-colored.svg';

import styles from './styles.module.scss';

const WeBelievePage = observer(() => {
  const { openModal } = ModalStore;

  const notify = () => toast('Copied!');

  const copyHandler = (value: string) => {
    notify();
    navigator.clipboard.writeText(value);
  };

  return (
    <div className={styles.weBelievePage}>
      <ToastContainer limit={3} autoClose={1500} />
      <div className={styles.container}>
        <Header />
        <div className={styles.intro} id='intro'>
          <h2 className={styles.title}>We believe</h2>
          <span className={styles.description}>
            By building this service we believe that we help HoReCa business to
            answer world calls caused by COVID and Global Warming due to CO2
            production.
            <br /> We have only started our journey and there are way more
            benefits through our ideas and solutions to come!
            <br /> Join us and meet 3 bright-minded enthusiast who are trying to
            make this world a better place for everyone ❤️
          </span>
          <DefaultButton
            text='Try it 1-month Free'
            className={styles.button}
            onClick={openModal}
          />
        </div>
        <div className={styles.features}>
          <div className={styles.background}>
            <img src={BG} alt='' />
          </div>
          <div className={styles.content}>
            {WE_BELIEVE_ITEMS.map((item, index) => (
              <FeaturesItem {...item} className={styles.feature} key={index} />
            ))}
          </div>
        </div>
        <div className={styles.supportUs} id='supportUs'>
          <h2 className={styles.titleSecond}>Support Us</h2>
          <span className={styles.description}>
            Did our beliefs resonate in your ❤️? Help us grow our assistance to
            HoReCa business and get some candies for kids
          </span>
          <div className={styles.cryptoItems}>
            <div className={styles.cryptoItem}>
              <img src={Bitcoin} alt='' className={styles.icon} />
              <span className={styles.cryptoTitle}>Bitcoin wallet</span>
              <div className={styles.wallet}>
                <Input
                  value='3Hd5WYmVDMdW8XKjuSgpZo57gARTDfJPYF?amount=0...'
                  onChange={() => {}}
                />
                <div
                  className={styles.copy}
                  onClick={() =>
                    copyHandler(
                      '3Hd5WYmVDMdW8XKjuSgpZo57gARTDfJPYF?amount=0...'
                    )
                  }
                >
                  <img src={Copy} alt='' />
                </div>
              </div>
            </div>
            <div className={styles.cryptoItem}>
              <img src={Etherium} alt='' className={styles.icon} />
              <span className={styles.cryptoTitle}>Ethereum wallet</span>
              <div className={styles.wallet}>
                <Input
                  value='0x6D7a25d2CF876A98327074aB9BDd1ED834dc8D97'
                  onChange={() => {}}
                />
                <div
                  className={styles.copy}
                  onClick={() =>
                    copyHandler('0x6D7a25d2CF876A98327074aB9BDd1ED834dc8D97')
                  }
                >
                  <img src={Copy} alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
});

export default WeBelievePage;
