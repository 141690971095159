import React from 'react';
import Journey from './../../../assets/images/start-journey.png';
import { ReactComponent as Arrow } from './../../../assets/svg/forward-white.svg';

import styles from './styles.module.scss';
import DefaultButton from '../../Common/DefaultButton';
import { Link } from 'react-router-dom';
import LinkButton from '../../Common/LinkButton';
import { observer } from 'mobx-react-lite';
import ModalStore from '../../../stores/ModalStore';

const StartJourney = observer(() => {
  const { openModal } = ModalStore;

  return (
    <div className={styles.startJourney}>
      {/* <div className={styles.background}>
        <img src={JourneyBG} alt='' />
      </div> */}
      <div className={styles.content}>
        <div className={styles.image}>
          <img src={Journey} alt='' className={styles.img} />
          <DefaultButton
            text='Try it for Free'
            svgPosition='end'
            SvgElement={Arrow}
            className={styles.buttonMobile}
          />
          <Link to='/uj/menu'>
            <LinkButton
              text='See Online Menu example'
              className={styles.linkMobile}
            />
          </Link>
        </div>
        <div className={styles.info}>
          <h3 className={styles.title}>Start your journey now</h3>
          <div className={styles.description}>
            Branded QR and online menu page, feedback & reviews, social network
            growth, end-to-end statistics, online support - all free for you to
            join new era of making your guests satisfied & happy!
          </div>
          <DefaultButton
            text='Try it for Free'
            svgPosition='end'
            SvgElement={Arrow}
            className={styles.button}
            onClick={openModal}
          />
          <Link to='/uj/menu'>
            <LinkButton
              text='See Online Menu example'
              className={styles.link}
            />
          </Link>
        </div>
      </div>
    </div>
  );
});

export default StartJourney;
