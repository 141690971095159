import { BrowserRouter } from 'react-router-dom';
import Routes from '../../routes';
import ComingSoonModal from '../Common/ComingSoonModal';

import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <BrowserRouter>
      <Routes />
      <ComingSoonModal />
    </BrowserRouter>
  );
};

export default App;
