import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';
import { ToastContainer, toast } from 'react-toastify';

import MenuStore from '../../stores/MenuStore';

import Time from './../../assets/svg/time.svg';
import Location from './../../assets/svg/location.svg';
import Phone from './../../assets/svg/phone.svg';
import Copy from './../../assets/svg/copy.svg';
import Back from './../../assets/svg/back.svg';
import Header from './../../assets/images/rest.png';
import Down from './../../assets/svg/down.svg';
import Up from './../../assets/svg/up.svg';

import DefaultButton from '../../components/Common/DefaultButton';
import RateUs from '../../components/DetailsCommon/RateUs';
import SocialItem from '../../components/DetailsCommon/SocialItem';
import Loader from '../../components/Common/Loader';
import Workdays from '../../components/DetailsCommon/Workdays';

import { WORKDAYS } from '../../utils/constants';

import styles from './styles.module.scss';

const DetailsPage = observer(() => {
  const { id } = useParams<{ id: string }>();
  const { loadMenuData, menuData, isLoading } = MenuStore;

  const [isWorkdaysVisible, setIsWorkdaysVisible] = useState(false);

  useEffect(() => {
    if (!menuData) {
      loadMenuData(id);
    }
  }, [id, loadMenuData, menuData]);

  const notify = () => toast('Copied!');

  const copyPhone = () => {
    navigator.clipboard.writeText(menuData?.phone || '');
    notify();
  };

  const copyAdress = () => {
    navigator.clipboard.writeText(menuData?.address || '');
    notify();
  };

  const getWorkingTime = () => {
    const dayIndex = new Date().getDay();

    const currentDay = WORKDAYS[dayIndex];

    return menuData?.workHours[currentDay.key];
  };

  const toggleIsWorkdaysVisible = () => {
    setIsWorkdaysVisible((prev) => !prev);
  };

  if (isLoading || !menuData) return <Loader />;

  return (
    <div className={styles.detailsPage} id='info'>
      <ToastContainer limit={3} autoClose={1500} />
      <div className={styles.container}>
        <div className={styles.header}>
          <img src={Header} alt='Logo' />
        </div>
        <div className={styles.info}>
          <span className={styles.subtitle}>Welcome at</span>
          <span className={styles.title}>{menuData.name}</span>
          <div>
            <div className={styles.infoItem} onClick={toggleIsWorkdaysVisible}>
              <div className={styles.infoItemContent}>
                <img
                  className={styles.infoItemContentIcon}
                  src={Time}
                  alt='Working time'
                />
                <span>{`${getWorkingTime()?.from} - ${
                  getWorkingTime()?.to
                }`}</span>
                <img
                className={styles.dropWorkdays}
                src={isWorkdaysVisible ? Up : Down}
                alt=''
              />
              </div>
              
            </div>
            <Workdays
              workdays={menuData.workHours}
              isVisible={isWorkdaysVisible}
            />
            <div className={styles.infoItem}>
              <div className={styles.infoItemContent}>
                <img
                  className={styles.infoItemContentIcon}
                  src={Location}
                  alt='Location'
                />
                <a
                  href={`https://maps.google.com/?q=${menuData.address}`}
                  className={styles.phoneLink}
                  target='_blank'
                  rel='noreferrer'
                >
                  {menuData.address}
                </a>
              </div>
              <img
                className={styles.infoCopy}
                src={Copy}
                alt=''
                onClick={copyAdress}
              />
            </div>
            <div className={styles.infoItem}>
              <div className={styles.infoItemContent}>
                <img
                  className={styles.infoItemContentIcon}
                  src={Phone}
                  alt='Phone'
                />
                <a href={`tel: ${menuData.phone}`} className={styles.phoneLink}>
                  {menuData.phone}
                </a>
              </div>
              <img
                className={styles.infoCopy}
                src={Copy}
                alt=''
                onClick={copyPhone}
              />
            </div>
          </div>
          <div className={styles.followUs}>
            <span className={styles.subtitle}>Follow us</span>
            <div className={styles.socialItems}>
              {menuData.links.map((social) => (
                <SocialItem social={social} key={social.type} />
              ))}
            </div>
          </div>
          <div className={styles.description}>{menuData.description}</div>
          <RateUs menuData={menuData} />
        </div>
      </div>
      <div className={styles.detailsFooter}>
        <Link to={`/${id}/menu`}>
          <DefaultButton text='Back to menu' svg={Back} svgPosition='start' />
        </Link>
      </div>
    </div>
  );
});

export default DetailsPage;
