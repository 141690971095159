import Footer from '../../components/Common/Footer';
import Header from '../../components/Common/Header';
import Benefits from '../../components/LandingCommon/Benefits';
import Features from '../../components/LandingCommon/Features';
import Intro from '../../components/LandingCommon/Intro';
import PerfectlyFit from '../../components/LandingCommon/PerfectlyFit';
import Pricing from '../../components/LandingCommon/Pricing';
import StartJourney from '../../components/LandingCommon/StartJourney';

import styles from './styles.module.scss';

const LandingPage = () => {
  return (
    <div className={styles.landingPage}>
      <div className={styles.container}>
        <Header />
        <Intro />
        <Benefits />
        <PerfectlyFit />
        <Features />
        <Pricing />
        <StartJourney />
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
