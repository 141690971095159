import Download from './../assets/images/benefits/download.png';
import Guests from './../assets/images/benefits/guests.png';
import Reviews from './../assets/images/benefits/reviews.png';
import Page from './../assets/images/benefits/page.png';

import Bars from './../assets/images/perfectlyFits/bars.png';
import Cafes from './../assets/images/perfectlyFits/cafes.png';
import Markets from './../assets/images/perfectlyFits/markets.png';
import Coffee from './../assets/images/perfectlyFits/coffee.png';
import Restaurants from './../assets/images/perfectlyFits/restaurants.png';
import Hotels from './../assets/images/perfectlyFits/hotels.png';
import Pizza from './../assets/images/perfectlyFits/pizza.png';

import Easy from './../assets/images/features/easy.png';
import Support from './../assets/images/features/support.png';
import Feedback from './../assets/images/features/feedback.png';
import Statistics from './../assets/images/features/statistics.png';
import Qr from './../assets/images/features/qr.png';
import Pay from './../assets/images/features/pay.png';
import Pandemic from './../assets/images/features/pandemic.png';
import Guest from './../assets/images/features/guests.png';

import Reopnening from './../assets/images/weBelieve/reopening.png';
import Damage from './../assets/images/weBelieve/damage.png';
import Movement from './../assets/images/weBelieve/movement.png';
import Risk from './../assets/images/weBelieve/risk.png';

export const BENEFITS_ITEMS = [
  {
    img: Download,
    title: 'No need to download an application',
    description:
      'Scan QR code with your phone camera and navigate through lovely interactive menu web page',
    linkTitle: 'See example',
    linkPath: '/uj/menu',
  },
  {
    img: Guests,
    title: 'Help your guest stay with you longer',
    description:
      'Grow your social network presence allowing your guests quickly find & follow you at Instagram, Telegram, Twitter, Facebook, VK, Whatsapp...',
  },
  {
    img: Reviews,
    title: 'Mature your reviews at top world-know platforms',
    description:
      'Short description automatically grow trust through Tripavisor, Google Business, Yelp, Foody.vn, Dianping and othersion',
  },
  {
    img: Page,
    title: 'Online adaptive page & branded QR menu',
    description:
      'Add your logo & color your menu page right in your style. Same for QR - brand them all! ',
  },
];

export const PLACE_ITEMS = [
  {
    img: Bars,
    text: 'Bars',
  },
  {
    img: Cafes,
    text: 'Cafes',
  },
  {
    img: Markets,
    text: 'Food markets',
  },
  {
    img: Coffee,
    text: 'Coffehouses',
  },
  {
    img: Restaurants,
    text: 'Restaurants',
  },
  {
    img: Hotels,
    text: 'Hotels',
  },
  {
    img: Pizza,
    text: 'Pizzarias',
  },
];

export const FEATURES_ITEMS = [
  {
    img: Easy,
    title: 'Easy and friendly to use',
    description: 'Switching to QR menu',
  },
  {
    img: Support,
    title: 'Support the Net Zero Carbon Movement',
    description: 'Go digital, the less paper is used the better!',
    linkTitle: 'See our beliefs',
    linkPath: '/believe#intro',
  },
  {
    img: Feedback,
    title: 'Work with guest feedback',
    description:
      'Help your satisfied guest to spread a word about you, while get negative reviews only to your inbox directly',
  },
  {
    img: Statistics,
    title: 'Collect and learn on your statistics',
    description:
      'QR scans, Average time on the menu, Link clicks, Feedbacks & reviews',
  },
  {
    img: Qr,
    title: 'Switching to QR menu is easy, using the QR menu is pure pleasure!',
    description:
      'Pick a color, add your logo and just click download - we have already prepared QRs for printing!',
    linkTitle: 'Menu example',
    linkPath: '/uj/menu',
  },
  {
    img: Pay,
    title: 'Pay as you go, cancel anytime',
    description:
      'Start free, then pay on monthly or yearly basis with 17.5% discount',
  },
  {
    img: Pandemic,
    title: 'Support pandemic relief',
    description:
      'Go digital! Stay compliant for any reopening regulations! Show your guests and the team that you care!',
  },
  {
    img: Guest,
    title: 'Enhance your guests experience',
    description:
      'Welcome your guests, no need to wait for the menu, no need for asking wifi password - what gives time for the team to focus',
  },
];

export const PRICING_ITEMS = [
  {
    name: 'Starter',
    cost: 'Free',
    period: 'month',
    highlighted: false,
    features: [
      'No credit card required',
      'All features 1 month free',
      'Chat support only',
    ],
  },
  {
    name: 'Popular',
    cost: '$15',
    period: 'monthly',
    highlighted: true,
    features: [
      'All features',
      'Chat & call support',
      'Discounts & beta-testing',
    ],
  },
  {
    name: 'Pro',
    cost: '$130',
    period: 'yearly',
    highlighted: false,
    features: [
      'All features',
      'Chat & call support',
      'Discounts & beta-testing',
    ],
  },
];

export const WE_BELIEVE_ITEMS = [
  {
    img: Reopnening,
    title: 'Support reopening regulations',
    description: 'Stay compliant for any reopening regulations! Show authorities, your guests and the team that you care',
  },
  {
    img: Damage,
    title: 'Quarantine has caused significant damage',
    description: '',
  },
  {
    img: Movement,
    title: 'Support the Net Zero Carbon Movement',
    description: 'Go digital, the less paper is used the better!',
  },
  {
    img: Risk,
    title: 'Printed menus present a high risk of virus transmission',
    description: '',
  },
];
