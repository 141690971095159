import React from 'react';
import { RATE_STAGES } from '../../../utils/constants';
import DefaultButton from '../../Common/DefaultButton';
import { iRateUsDefault } from '../types';
import Love from './../../../assets/images/love.png';
import Sad from './../../../assets/images/sad.png';

import styles from './styles.module.scss';

const RateUsDefault = ({ onStage }: iRateUsDefault) => {
  return (
    <>
      <div className={styles.title} id='rateUs'>
        Rate us
      </div>
      <div className={styles.subtitle}>
        Help us get better or spread some love
      </div>
      <div className={styles.rateUs}>
        <div
          className={styles.rateUsItem}
          onClick={() => onStage(RATE_STAGES.SAD_FORM)}
        >
          <div className={styles.top}>
            <img src={Sad} alt='Ohh...' />
          </div>
          <DefaultButton text='Ohh...' />
        </div>
        <div
          className={styles.rateUsItem}
          onClick={() => onStage(RATE_STAGES.LOVE_FINAL)}
        >
          <div className={styles.top}>
            <img src={Love} alt='Ohh...' />
          </div>
          <DefaultButton text='Love it!' />
        </div>
      </div>
    </>
  );
};

export default RateUsDefault;
