import React from 'react';
import { HashLink } from 'react-router-hash-link';
import DefaultButton from '../../Common/DefaultButton';
import About from './../../../assets/svg/about.svg';
import Rate from './../../../assets/svg/rate.svg';

import styles from './styles.module.scss';

function MenuFooter({ id }: { id: string }) {
  return (
    <div className={styles.menuFooter}>
      <div className={styles.buttons}>
        <HashLink to={`/${id}/details#info`}>
          <DefaultButton text='About Us' svg={About} svgPosition="end"/>
        </HashLink>
        <HashLink to={`/${id}/details#rateUs`}>
          <DefaultButton text='Rate Us' svg={Rate} svgPosition="end"/>
        </HashLink>
      </div>
    </div>
  );
}

export default MenuFooter;
